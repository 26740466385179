require('./bootstrap');

import Vue from 'vue'

import store from './store/index'

import vSelect from 'vue-select';

Vue.component('v-select', vSelect);

import 'vue-select/dist/vue-select.css';

import Toasted from 'vue-toasted';

Vue.use(Toasted);

const { EncryptStorage } = require('encrypt-storage');

const encryptStorage = new EncryptStorage('$2y$10$KqB2fi2qHO4EmPM40mc7jubbJT2pM4Sg02eZbG3t1fYKBUpMi1Klm', {
    prefix: '@google',
    storageType: 'localStorage',
});

Vue.prototype.$storage = encryptStorage
window.$storage = encryptStorage

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

Vue.toasted.register('error',
    (payload) => {
        if (!payload.message) {
            return "Oups quelque chose c'est mal passé"
        }
        return "Oops.. " + payload.message;
    },
    {
        type: 'error',
        duration: 6000,
    }
)
Vue.toasted.register('all',
    (payload) => {
        if (!payload.message) {
            return "Opération effectuée"
        }
        return `${payload.message}`;
    },
    {
        type: 'success',
        duration: 6000,
    }
)

import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCnNJtmWA1m6fDK9F9n_pREywLzY5mLsCo",
    authDomain: "nsia-e-commerce-e3a29.firebaseapp.com",
    projectId: "nsia-e-commerce-e3a29",
    storageBucket: "nsia-e-commerce-e3a29.appspot.com",
    messagingSenderId: "1063013772375",
    appId: "1:1063013772375:web:73082808c34439e9a70712",
    measurementId: "G-XDTQLY4119"
};

firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

window.db = db

db.settings({
    timestampsInSnapshots: true,
    merge: true
})

import { api, assets, router, format, uuidGen } from './helpers/shared';

Vue.component('admin-dashboard', () => import('./pages/admin/dashboard/index'));
Vue.component('admin-users', () => import('./pages/admin/users/index'));
Vue.component('api-users', () => import('./pages/admin/api/users/index'));

Vue.component('admin-profile', () => import('./pages/admin/users/profile'));
Vue.component('admin-customers', () => import('./pages/admin/users/customers'));
Vue.component('admin-promo', () => import('./pages/admin/promo/index'));
Vue.component('admin-subscription', () => import('./pages/admin/subscription/list'));
Vue.component('me-subscription', () => import('./pages/admin/subscription/me'));
Vue.component('admin-suggestion', () => import('./pages/admin/suggestion/index'));
Vue.component('admin-suggestion-detail', () => import('./pages/admin/suggestion/detail'));
Vue.component('admin-prospect', () => import('./pages/admin/prospect/list'));
Vue.component('admin-prospect-detail', () => import('./pages/admin/prospect/detail'));
Vue.component('admin-subscription-detail', () => import('./pages/admin/subscription/detail'));
Vue.component('admin-subscription-me-detail', () => import('./pages/admin/subscription/detailMe'));
Vue.component('admin-assurance-auto', () => import('./pages/admin/subscription/contrat/assAuto'));
Vue.component('admin-assurance-cima', () => import('./pages/admin/subscription/contrat/assCima'));
Vue.component('admin-assurance-incorporation', () => import('./pages/admin/subscription/contrat/assIncorporation'));

Vue.component('admin-reporting', () => import('./pages/admin/reporting/list'));
Vue.component('admin-categorie-intermediaire', () => import('./pages/admin/CategorieIntermediaire/index'));
Vue.component('admin-intermediaire', () => import('./pages/admin/intermediaire/index'));

//Client
Vue.component('terms-app', () => import('./pages/terms/index'));
Vue.component('subscription-wait', () => import('./pages/client/subscription/wait'));
Vue.component('client-profile', () => import('./pages/client/profile/index'));
Vue.component('voyage', () => import('./pages/assurance/voyage'));
Vue.component('voyage-subscription', () => import('./pages/assurance/voyage-subscription'));
Vue.component('habitation', () => import('./pages/assurance/apefa'));
Vue.component('automobile', () => import('./pages/assurance/automobile'));
Vue.component('basket', () => import('./pages/basket/index'));
Vue.component('mdBasket', () => import('./pages/basket/mdLink'));
Vue.component('basket-client', () => import('./pages/basket/clientBasket'));
Vue.component('basket-client-lg', () => import('./pages/basket/clientBasketLg'));
Vue.component('habitation-subscription', () => import('./pages/assurance/habitation-subscription'));
Vue.component('auto-subscription', () => import('./pages/assurance/auto-subscription'));
Vue.component('pannier', () => import('./pages/basket/pannier'));
Vue.component('client-subscription', () => import('./pages/client/subscription/list'));
Vue.component('client-subscription-save', () => import('./pages/client/subscription/save'));
Vue.component('home-page', () => import('./pages/home/index'));
Vue.component('client-dashboard', () => import('./pages/client/dashboard/index'));
Vue.component('suggestion', () => import('./pages/suggestion/index'));
Vue.component('contact-form', () => import('./pages/contact/index'));
Vue.component('burgger', () => import('./components/burgger/index'));
Vue.component('checkOut', () => import('./pages/assurance/checkout'));
Vue.component('client-subscription-detail', () => import('./pages/client/subscription/detail'));
Vue.component('client-subscription-continous', () => import('./pages/client/subscription/continous'));
Vue.component('subscription-promo', () => import('./pages/admin/promo/subscription'));

Vue.component('intgest-subscription', () => import('./pages/intGest/subscription/list'));
Vue.component('intgest-prospect', () => import('./pages/intGest/prospect/list'));
Vue.component('intgest-prospect-detail', () => import('./pages/intGest/prospect/detail'));
Vue.component('intgest-subscription-detail', () => import('./pages/intGest/subscription/detail'));
Vue.component('intgest-profile', () => import('./pages/intGest/profile/index'));

Vue.component('api-requests', () => import('./pages/admin/api/requests/index'));

Vue.prototype.$assets = (...args) => assets(...args);
Vue.prototype.$router = (...args) => router(...args);
Vue.prototype.$document = (...args) => window.$(...args);
Vue.prototype.$routing = (...args) => api(...args);
Vue.prototype.$flatpickr = (...args) => flatpickr(...args);
Vue.prototype.$format = (...args) => format(...args);

Vue.prototype.$db = (...args) => db(...args);
Vue.prototype.$uuid = () => uuidGen();

Vue.prototype.$swal = window.Swal

// new Vue({
//     store,
//     el: '#app',
// });

new Vue({
    store
}).$mount('#app');
