const state = {
    files: []
}
export default {
    namespaced: true,
    state: state,
    mutations: {
        MUTATE_ADD_FILE(state, file) {
            state.files.push(file)
        },
        MUTATE_REMOVE_FILE(state, index) {
            state.files.splice(index, 1)
        }
    },
    getters: {
        files: state => state.files
    },
    actions: {

    }
}
