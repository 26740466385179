import Vue from 'vue';

export const EventBus = new Vue();

export function assets(data) {
    //return 'http://nsia.local/' + data;
    //return 'http://192.168.1.76:8002/' + data;
    //return 'http://165.22.28.198:8088/' + data;
    //return 'https://nsiadirect.tg/' + data
    return 'https://dev.directnsia.tg/' + data
}

export function api(data) {
   // return 'http://nsia.local/' + data;
    //return 'http://192.168.1.76:8002/' + data;
    //return 'http://165.22.28.198:8088/' + data;
    //return 'https://nsiadirect.tg/' + data
    return 'https://dev.directnsia.tg/' + data
}

export function scheme() {
    return 'https://';
}

export function router(data) {
    return window.location.href = data
}

export function _buildQuery(params) {
    let string = '';

    for (let key in params) {
        if (params[key] !== "") {
            string += `${key}=${params[key]}&`
        }
    }
    string = '?' + string.substring(0, string.length - 1);
    return string;
}

export function format(num) {
    return num;
    //return String(num).replace(/(?<!\/..*)(\d)(?=(?:\d{3})+(?:\/.|$))/g, '$1 ');
}

export function uuidGen() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16)
    })
}
